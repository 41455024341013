<template>
    <div>
        <van-nav-bar title="费用报销" left-text="返回" @click-left="back" fixed left-arrow style="z-index:1000" />
        <div class="content">
            <van-tabs v-model="active" @change="activeChange">
                <van-tab title="费用报销" :name="0"></van-tab>
                <van-tab title="单据列表" :name="1"></van-tab>
                <van-tab title="单据审核" :name="2"></van-tab>
            </van-tabs>
            <!-- #region 费用报销 -->
            <div class="edit" v-if="active == 0">
                <van-form class="edit_form" label-width="140">
                    <!-- 报销人 -->
                    <van-field label="报销人" @click="ownerIdShowCli(1)" readonly clickable required>
                        <template #input>
                            <span v-if="user.userNameNeedTranslate == 1 && formshowText.name"><TranslationOpenDataText type='userName'
                                    :openid='formshowText.name'></TranslationOpenDataText></span>
                            <span v-else>{{ formshowText.name }}</span>
                        </template>
                    </van-field>
                    <div v-if="auditTypeItem.auditType == 2">
                        <van-field label="第一审核人" @click="ownerIdShowCli(2)" readonly clickable required :disabled="editForm.type == '14454'">
                            <template #input>
                                <span v-if="user.userNameNeedTranslate == 1"><TranslationOpenDataText type='userName'
                                        :openid='firstCheckerText.name'></TranslationOpenDataText></span>
                                <span v-else>{{ firstCheckerText.name }}</span>
                            </template>
                        </van-field>
                        <van-field label="第二审核人" @click="ownerIdShowCli(3)" readonly clickable required :disabled="editForm.type == '14454'">
                            <template #input>
                                <span v-if="user.userNameNeedTranslate == 1"><TranslationOpenDataText type='userName'
                                        :openid='secondCheckerText.name'></TranslationOpenDataText></span>
                                <span v-else>{{ secondCheckerText.name }}</span>
                            </template>
                        </van-field>
                    </div>
                    <van-popup v-model="ownerIdShow" position="bottom" v-if="canExamine" style="height: 90%">
                        <div class="popupDiv paddingTop">
                            <div class="popupCon conBorder">
                                <van-radio-group v-model="userRadio" v-if="user.userNameNeedTranslate == '1'">
                                    <div v-for="item in userList" :key="item.id">
                                        <van-radio :name="item" class="popupItem marginNone borderNone" v-if="item.id != user.id || ownerIdShowType == 1">
                                            <!-- <TranslationOpenDataText type='userName' :openid='item.name'></TranslationOpenDataText> -->
                                            <span class="userNameClass_left">
                                                <TranslationOpenDataText type='userName' :openid='item.name'></TranslationOpenDataText>
                                            </span>
                                            <span class="userNameClass_right">
                                                {{ item.jobNumber }}
                                            </span>
                                        </van-radio>
                                    </div>
                                </van-radio-group>
                                <van-radio-group v-model="userRadio" v-else>
                                    <div v-for="item in userList" :key="item.id">
                                        <van-radio :name="item" class="popupItem marginNone borderNone" v-if="item.id != user.id || ownerIdShowType == 1">
                                        <!-- {{ item.name }} -->
                                            <span class="userNameClass_left">{{item.name}}</span>
                                            <span class="userNameClass_right">{{ item.jobNumber }}</span>
                                        </van-radio>
                                    </div>
                                </van-radio-group>
                            </div>
                            <div class="popupBtn">
                                <van-button style="width:100%;background: #1989fa;color: #ffffff;" round
                                @click="ownerIdChange()">确定</van-button>
                            </div>
                        </div>
                    </van-popup>
                    <!-- 填报日期 -->
                    <van-field v-model="editForm.createDate" label="填报日期" @click="createDateShow = true" readonly clickable
                        required></van-field>
                    <van-popup v-model="createDateShow" position="bottom">
                        <van-datetime-picker type="date" title="选择填报日期" @confirm="createDateChange" v-model="currentDate1"
                            @cancel="createDateShow = false; $forceUpdate();" :min-date="minDate" :max-date="maxDate" />
                    </van-popup>
                    <!-- 发票张数 -->
                    <van-field label="发票张数" v-if="user.timeType.easyExpense==0 && editForm.type != '14454'">
                        <template #input>
                            <van-stepper v-model="editForm.ticketNum" disable-input @plus="ticNumChange(1)"
                                @minus="ticNumChange(0)" />
                        </template>
                    </van-field>
                    <!-- 费用类型 -->
                    <van-field v-model="editForm.type" label="费用主类型" @click="typeShow = true" readonly clickable>
                        <template #input>{{ expenseMainType.text }}</template>
                    </van-field>
                    <van-popup v-model="typeShow" position="bottom">
                        <van-picker show-toolbar :columns="typeList" @confirm="typeChange"
                            @cancel="typeShow = false; $forceUpdate();" />
                    </van-popup>
                    <van-field label="设备归属人" @click="equipmentOwnershipVisable = true" readonly clickable required v-if="editForm.type == '14454'">
                        <template #input>
                            <span>{{ equipmentOwner.name }}</span>
                        </template>
                    </van-field>
                    <van-popup v-model="equipmentOwnershipVisable" position="bottom">
                        <van-picker show-toolbar :columns="equipmentOwnerList" @confirm="deviceTypeChange"
                            @cancel="equipmentOwnershipVisable = false; $forceUpdate();" />
                    </van-popup>
                    <!-- 备注 -->
                    <van-field v-model="editForm.remark" label="备注" type="textarea" maxlength="100" :disabled="editForm.type == '14454'"></van-field>
                    <!-- 发票 -->
                    <van-field label="发票" readonly>
                        <template #input>总费用： ￥{{ totalCost }}</template>
                    </van-field>
                    <div class="invoice" v-if="invoiceList.length != 0">
                        <div v-for="item, index in invoiceList" :key="item.id" style="position:relative"
                            :class="index == 0 ? '' : 'invoice_item'">
                            <!-- <van-button class="deletebtn" size="mini" type="default" @click="deleteInvoice(index)">删除</van-button> -->
                            <van-icon name="delete-o" class="deletebtn" v-if="index != 0" @click="deleteInvoice(index)" />
                            <van-field label="所属项目：" v-model="item.projectId"
                                @click="in_projectShow = true, invoiceIndex = index" readonly clickable required>
                                <template #input>{{ formshowText.inProjectName[index] }}</template>
                            </van-field>
                            <van-field label="费用日期：" v-model="item.happenDate"
                                @click="in_dateShow = true, invoiceIndex = index" readonly clickable required></van-field>
                            <template v-if="!(user.companyId == mechanicalCompanyId && user.id != editForm.ownerId && editForm.type == 14454)">
                                <van-field label="发票种类：" v-model="item.invoiceType" v-if="user.timeType.easyExpense==0"
                                    @click="in_typeShow = true, invoiceIndex = index" readonly clickable required>
                                    <template #input>{{ inTypeList[item.invoiceType] }}</template>
                                </van-field>
                            </template>
                            <van-field label="费用类型：" v-model="item.expenseType"
                                @click="in_exTypeShow = true, invoiceIndex = index" readonly clickable required></van-field>
                            <template v-if="!(user.companyId == mechanicalCompanyId && user.id != editForm.ownerId && editForm.type == 14454)">
                                <van-field :label="`${user.timeType.easyExpense==0?'费用金额（含税）':'费用金额'}：`" v-model="item.amount" type="number" required></van-field>
                                <van-field label="发票号：" v-model="item.invoiceNo" v-if="user.timeType.easyExpense==0"></van-field>
                                <van-field label="税率%：" v-model="item.taxPercent" v-if="user.timeType.easyExpense==0"></van-field>
                                <van-field label="税额：" readonly v-if="user.timeType.easyExpense==0">
                                    <template #input>￥{{ getTaxValue(item.amount, item.taxPercent) }}</template>
                                </van-field>
                                <van-field label="备注：" v-model="item.remark" autosize maxlength="100"></van-field>
                                <!-- <van-field label="报销凭证：" @click="invoiceIndex = index" clickable>
                                    <template #input>
                                        <van-uploader v-model="uploader[index]" :before-read="beforeRead"
                                            :after-read="afterRead" @delete="item.pic = null" :max-count="1" />
                                    </template>
                                </van-field> -->
                                <van-field @click="invoiceIndex = index" clickable label-width="0em">
                                    <template #input>
                                        <div class="imgListVan">
                                            <div>报销凭证：</div>
                                            <div class="vanUploaderImg">
                                                <van-uploader v-model="uploader[index]" :before-read="beforeRead" multiple
                                                :after-read="afterRead" @delete="(file, Item) => {deteleImg(file, Item, index)}" :max-count="10" />
                                            </div>
                                        </div>
                                        
                                    </template>
                                </van-field>
                            </template>
                        </div>
                    </div>
                    <div class="addinvoice"><van-button size="small" icon="plus" type="info" plain hairline
                            @click="addInvoice">添加发票</van-button></div>
                    <!-- 发票-popup -->
                    <span>
                        <!-- 所属项目 --> 
                        <!-- <van-popup v-model="in_projectShow" position="bottom">
                            <van-picker value-key="projectName" show-toolbar :columns="inProjectList"
                                @confirm="inProjectChange" @cancel="in_projectShow = false; $forceUpdate();" />
                        </van-popup> -->
                        <van-popup v-model="in_projectShow" position="bottom" style="height: 84%">
                            <div class="popupDiv">
                                <div class="popupSearch">
                                    <van-search v-model.trim="projectSelectVal" shape="round" background="#F4F4F4" placeholder="请输入项目名称/编号" @clear="projectSelect()" @blur="projectSelect()" @search="projectSelect()" @input="projectSelect()"/>
                                </div>
                                <div class="popupCon">
                                    <div v-for="(item, index) in inProjectList" :key="item.id" class="popupItem paddingDiv" @click="inProjectChange(item, index)">
                                        <p class="popupItemOne" v-if="item.projectName">{{item.projectName}}</p>
                                        <p class="popupItemTwo" v-if="item.projectCode">{{item.projectCode}}</p> 
                                    </div>
                                </div>
                            </div>
                        </van-popup>

                        <!-- 费用日期 -->
                        <van-popup v-model="in_dateShow" position="bottom">
                            <van-datetime-picker type="date" title="选择费用日期" @confirm="inDateChange" v-model="currentDate2"
                                @cancel="in_dateShow = false; $forceUpdate();" :min-date="minDate" :max-date="maxDate" />
                        </van-popup>
                        <!-- 发票种类 -->
                        <van-popup v-model="in_typeShow" position="bottom">
                            <van-picker show-toolbar :columns="inTypeList" @confirm="inTypeChange"
                                @cancel="in_typeShow = false; $forceUpdate();" />
                        </van-popup>
                        <!-- 费用类型 -->
                        <van-popup v-model="in_exTypeShow" position="bottom">
                            <van-picker value-key="typeName" show-toolbar :columns="inexTypeList" @confirm="inexTypeChange"
                                @cancel="in_exTypeShow = false; $forceUpdate();" />
                        </van-popup>
                    </span>
                </van-form>
                <!-- 提交 -->
                <div class="form_btn" style="position:fixed; bottom:0px;width:100%;">
                    <div style="padding-bottom:10px;">
                        <van-button square block type="info" @click="submitExpense" :loading="confirmLoading"
                            style="width:100%;float:left;">提交</van-button>
                    </div>
                </div>

            </div>
            <!-- #endregion -->
            <!-- 单据列表 -->
            <div class="list" v-if="active == 1">
                <!-- <van-pull-refresh v-model="downLoading" @refresh="onDownRefresh">
                <van-list v-model="uploading" :finished="upFinished" :immediate-check="false" :offset="100" finished-text="没有更多了" @load="onLoadList"></van-list>
            </van-pull-refresh> -->
                <van-collapse v-model="activeName" accordion class="list_collapse">
                    <van-collapse-item v-for="item in billList" :key="item.id" title="标题1" :name="item.id">
                        <template #title>
                            <div class="collapse_label_l">票据编号：{{ item.code }}</div>
                            <div class="collapse_label_r">报销人：
                                <span v-if="user.userNameNeedTranslate == 1"><TranslationOpenDataText type='userName'
                                        :openid='item.ownerName'></TranslationOpenDataText></span>
                                <span v-else>{{ item.ownerName }}</span>
                            </div>
                            <div class="collapse_label_l">金额： ￥{{ item.totalAmount | numtosum }}</div>
                            <div class="collapse_label_r">状态：<span :class="statusClass[item.status]">{{
                                statusList[item.status] }}</span></div>
                        </template>
                        <div class="wrapper">
                            <div><span>{{ user.companyId == mechanicalCompanyId ? '工程名称' : '项目名称'}}：</span><span>{{ item.engineeringName }}</span></div>
                            <div><span>票据编号：</span><span>{{ item.code }}</span></div>
                            <div><span>金额：</span><span>￥{{ item.totalAmount | numtosum }}</span></div>
                            <div><span>报销人：</span>
                                <span v-if="user.userNameNeedTranslate == 1"><TranslationOpenDataText type='userName'
                                        :openid='item.ownerName'></TranslationOpenDataText></span>
                                <span v-else>{{ item.ownerName }}</span>
                            </div>
                            <div><span>填报日期：</span><span>{{ item.createDate }}</span></div>
                            <div v-if="user.timeType.easyExpense==0 && item.type != 14454"><span>发票张数：</span><span>{{ item.ticketNum }}</span></div>
                            <div><span>费用类型：</span><span>{{ item.expenseMainTypeName }}</span></div>
                            <!-- <div><span>状态：</span><span>{{item.status}}</span></div> -->
                            <!-- <div><span>驳回原因：</span><span>{{item.denyReason}}</span></div> -->
                            <div>
                                <span>审核流程：</span>
                                <span v-if="item.reviewProcess == 0">待第一审核人审核</span>
                                <span v-if="item.reviewProcess == 1">待第二审核人审核</span>
                                <span v-if="item.reviewProcess == 2">审核完成</span>
                                <span v-if="item.reviewProcess == 3">未提交</span>
                                <span v-if="item.reviewProcess == 4">已驳回</span>
                            </div>
                            <div v-if="item.type == 14454"><span>设备归属人：</span><span>{{ item.equipmentOwnerName }}</span></div>
                            <div><span>备注：</span><span>{{ item.remark }}</span></div>
                        </div>
                        <div class="operation">
                            <van-button size="small" type="info"
                                :to="{ name: 'expenseDetails', params: { id: item.id, canEdit: false } }">查看</van-button>
                            <template v-if="item.status != 0">
                                <van-button style="margin-left:10px" size="small" type="info"
                                :to="{ name: 'expenseDetails', params: { id: item.id, canEdit: true } }" v-if="item.type != 14454">编辑</van-button> 
                                <van-button style="margin-left:10px" size="small" type="info"
                                :to="{ name: 'customizedDetail', params: { id: item.id, canEdit: true } }"  v-if="item.type == 14454">编辑</van-button> 
                            </template>
                            <van-button style="margin-left:10px" size="small" type="danger"
                                @click="deleteBill(item.id)">删除</van-button>
                        </div>
                    </van-collapse-item>
                </van-collapse>
            </div>
            <!-- 单据审核 -->
            <div class="audit" v-if="active == 2">
                <van-collapse v-model="auditName" accordion class="list_collapse">
                    <van-collapse-item v-for="item in examineList" :key="item.id" title="标题2" :name="item.id">
                        <template #title>
                            <div class="collapse_label_l">票据编号：{{ item.code }}</div>
                            <div class="collapse_label_r">报销人：
                                <span v-if="user.userNameNeedTranslate == 1"><TranslationOpenDataText type='userName'
                                        :openid='item.ownerName'></TranslationOpenDataText></span>
                                <span v-else>{{ item.ownerName }}</span>
                            </div>
                            <div class="collapse_label_l">金额： ￥{{ item.totalAmount | numtosum }}</div>
                            <div class="collapse_label_r">状态：<span :class="statusClass[item.status]">{{
                                statusList[item.status] }}</span></div>
                            <div class="operation" v-if="auditTypeItem.auditType == 0">
                                <van-button size="small" type="info" :loading="item.approveLoading"
                                    @click.stop="approve(item)">通过</van-button>
                                <van-button style="margin-left:15px" size="small" type="danger"
                                    @click.stop="denyToReason(item.id)">驳回</van-button>
                            </div>
                            <div class="operation" v-if="auditTypeItem.auditType == 1">
                                <van-button size="small" type="info"
                                :to="{ name: 'expenseDetails', params: { id: item.id, canEdit: false, isAudit:true } }">审核</van-button>
                            </div>
                            <div class="operation" v-if="auditTypeItem.auditType == 2">
                                <van-button size="small" type="info" :loading="item.approveLoading"
                                    @click.stop="approve(item)">通过</van-button>
                                <van-button style="margin-left:15px" size="small" type="danger"
                                    @click.stop="denyToReason(item.id)">驳回</van-button>
                            </div>
                        </template>
                        <div class="wrapper">
                            <div><span>{{ user.companyId == mechanicalCompanyId ? '工程名称' : '项目名称'}}：</span><span>{{ item.engineeringName }}</span></div>
                            <div><span>票据编号：</span><span>{{ item.code }}</span></div>
                            <div><span>金额：</span><span>￥{{ item.totalAmount | numtosum }}</span></div>
                            <div><span>报销人：</span>
                                <span v-if="user.userNameNeedTranslate == 1"><TranslationOpenDataText type='userName'
                                        :openid='item.ownerName'></TranslationOpenDataText></span>
                                <span v-else>{{ item.ownerName }}</span>
                            </div>
                            <div><span>填报日期：</span><span>{{ item.createDate }}</span></div>
                            <div v-if="user.timeType.easyExpense==0 && item.type != '14454'"><span>发票张数：</span><span>{{ item.ticketNum }}</span></div>
                            <div><span>费用类型：</span><span>{{ item.expenseMainTypeName }}</span></div>
                            <div>
                                <span>审核流程：</span>
                                <span v-if="item.reviewProcess == 0">待第一审核人审核</span>
                                <span v-if="item.reviewProcess == 1">待第二审核人审核</span>
                                <span v-if="item.reviewProcess == 2">审核完成</span>
                                <span v-if="item.reviewProcess == 3">未提交</span>
                                <span v-if="item.reviewProcess == 4">已驳回</span>
                            </div>
                            <!-- <div><span>状态：</span><span>{{item.status}}</span></div> -->
                            <!-- <div><span>驳回原因：</span><span>{{item.denyReason}}</span></div> -->
                            <div v-if="item.type == 14454"><span>设备归属人：</span><span>{{ item.equipmentOwnerName }}</span></div>
                            <div><span>备注：</span><span>{{ item.remark }}</span></div>
                        </div>
                        <div class="lookup">
                            <van-button size="small" type="info"
                                :to="{ name: 'expenseDetails', params: { id: item.id, canEdit: false } }">查看单据信息</van-button>
                        </div>
                    </van-collapse-item>
                </van-collapse>
                <!-- <van-popup v-model="denyReasonDialog" position="bottom" closeable >
                <van-cell>请输入原因</van-cell>
                <van-field class="form_input"
                    v-model="denyParm.denyReason" name="reason" type="textarea" placeholder="请输入您决定驳回的原因"
                    rows="3" autosize  />
                <van-button style="width:100%;" type="info" :loading="denyLoading" @click="deny()">提交</van-button>
            </van-popup> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            auditTypeItem: {auditType: 0},
            active: sessionStorage.page ? JSON.parse(sessionStorage.page) : 0,
            user: JSON.parse(localStorage.userInfo),
            userList: [],
            canExamine: false,
            currentDate1: new Date(),
            currentDate2: new Date(),
            minDate: new Date(2020, 0, 1),
            maxDate: new Date(2025, 11, 31),
            confirmLoading: false,
            denyLoading: false,

            formshowText: {
                name: '',
                inProjectName: []
            },
            // #region 费用报销
            editForm: {
                ownerId: '',
                createDate: this.formatDate(new Date()),
                ticketNum: 0,
                type: 0,
                remark: ''
            },
            userRadio: null,
            ownerIdShow: false,
            ownerIdShowType: 1, // 1,报销人， 2第一审核人， 3第二审核人
            createDateShow: false,
            typeShow: false,
            typeList: [],

            invoiceIndex: 0,
            invoiceList: [
                {
                    projectId: '',
                    happenDate: '',
                    invoiceType: '',
                    expenseType: '',
                    amount: '',
                    invoiceNo: '',
                    taxPercent: '',
                    taxValue: '',
                    remark: '',
                    pic: '',
                }
            ],
            in_projectShow: false,
            in_dateShow: false,
            in_typeShow: false,
            in_exTypeShow: false,

            inProjectList: [],
            inProjectListCopy: [],
            inTypeList: ['增值税专用发票', '增值税普通发票'],
            allexTypeList: [],
            inexTypeList: [],
            uploader: [[]],
            // #endregion

            // 单据列表
            activeName: '',
            billList: [],
            statusList: ['审核通过', '待审核', '已驳回', '已撤销', '未填写'],
            statusClass: ['', 'waiting', 'rejected', '', 'rejected'],

            uploading: false,

            // 单据审核
            auditName: '',
            examineList: [],
            denyReasonDialog: false,
            denyParm: {
                id: '',
                denyReason: ''
            },
            expenseMainType: {
                text: ''
            },
            projectSelectVal: '',
            firstCheckerText: {},
            secondCheckerText: {},
            mechanicalCompanyId: 4811,
            equipmentOwnerList: [],
            equipmentOwner: {},
            equipmentOwnershipVisable: false
        }
    },
    computed: {
        totalCost() {
            let costnum = 0
            for (let i in this.invoiceList) {
                costnum += (this.invoiceList[i].amount ? parseFloat(this.invoiceList[i].amount) : 0)
            }
            return costnum.toFixed(2)
        }
    },
    filters: {
        numtosum(value) {
            if (value == undefined || !value) return '0.00'
            value = value.toFixed(2)
            const intPart = Math.trunc(value)
            const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
            let floatPart = '.00'
            const valueArray = value.toString().split('.')
            if (valueArray.length === 2) { // 有小数部分
                floatPart = valueArray[1].toString() // 取得小数部分
                return intPartFormat + '.' + floatPart
            }
            return intPartFormat + floatPart
        },
    },
    mounted() {
        this.formshowText.name = this.user.name;
        this.editForm.ownerId = this.user.id;
        this.userRadio = {
            name: this.user.name,
            id: this.user.id
        }
        this.activeChange()
        for (let i in this.user.functionList) {
            if (this.user.functionList[i].name == '费用审核') {
                this.canExamine = true
            }
        }
        if (!this.canExamine) {
            this.editForm.ownerId = this.user.id
            this.formshowText.name = this.user.name
        }
        
        this.getUserList()
        this.getProjectList()
        this.getExpensMainTypes()
        this.getExTypeList()
        this.getAuditType();
        if(this.user.companyId == this.mechanicalCompanyId) {
            this.getEquipmentOwnerList()
        }
    },
    methods: {
        // 项目搜索
        projectSelect() {
            if(this.projectSelectVal.length > 0) {
                let data = this.inProjectListCopy.filter(item => {return  item.projectName && item.projectName.includes(this.projectSelectVal)});
                let dataList = this.inProjectListCopy.filter(item => {return  item.projectCode && item.projectCode.includes(this.projectSelectVal)});
                let dataTree = data.concat(dataList)
                let arrList = Array.from(new Set(dataTree))
                this.inProjectList = arrList
            } else {
                this.inProjectList = JSON.parse(JSON.stringify(this.inProjectListCopy))
            }
        },
        back() {
            sessionStorage.removeItem("page");
            history.back();

        },
        formatDate(date) {
            let mon = date.getMonth() + 1
            return `${date.getFullYear()}-${mon < 10 ? '0' + mon : mon}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}`;
        },
        getTaxValue(amount, percent) {
            let per = percent / 100
            let amo = amount / (1 + per) * per
            return amo.toFixed(2)
        },
        activeChange() {
            sessionStorage.setItem('page', JSON.stringify(this.active))
            if (this.active == 1) {
                this.getBillList()
            }
            if (this.active == 2) {
                this.getExamineList()
            }
        },

        // #region 费用报销
        ownerIdChange() {
            if(this.ownerIdShowType == 1) {
                this.editForm.ownerId = this.userRadio.id
                this.formshowText.name = this.userRadio.name
            } else if(this.ownerIdShowType == 2) {
                this.$set(this.firstCheckerText, 'id', this.userRadio.id)
                this.$set(this.firstCheckerText, 'name', this.userRadio.name)
            } else if(this.ownerIdShowType == 3) {
                this.$set(this.secondCheckerText, 'id', this.userRadio.id)
                this.$set(this.secondCheckerText, 'name', this.userRadio.name)
            }
            this.ownerIdShow = false
        },
        createDateChange(value, key) {
            this.editForm.createDate = this.formatDate(value)
            this.createDateShow = false
        },
        typeChange(value, key) {
            this.separateProcessingAndJudgment(value)
            this.editForm.type = value.id
            this.expenseMainType.text = value.name
            this.typeShow = false
            for (let i in this.invoiceList) {
                this.invoiceList[i].expenseType = ''
            }
            this.inexTypeList = this.allexTypeList.filter(a => a.mainType == this.editForm.type)
        },
        deviceTypeChange(value) {
            console.log(value)
            this.equipmentOwner = value
            this.equipmentOwnershipVisable = false
        },
        separateProcessingAndJudgment(value) {
            if(this.user.companyId == this.mechanicalCompanyId) {
                if(value.id == '14454') {
                    this.userList = JSON.parse(JSON.stringify(this.userList)).filter(item => item.id != this.user.id)
                    const obj = { id: '', name: '' }
                    this.firstCheckerText = obj
                    this.secondCheckerText = obj
                    if(this.editForm.ownerId == this.user.id) {
                        this.editForm.ownerId = ''
                        this.formshowText.name = ''
                    }
                } else {
                    this.editForm.ownerId = this.user.id
                    this.formshowText.name = this.user.name
                    this.userList = JSON.parse(JSON.stringify(this.userList))
                }
            }
        },
        ticNumChange(value) {

            if (value) {
                this.invoiceList.push({
                    projectId: '',
                    happenDate: '',
                    invoiceType: '',
                    expenseType: '',
                    amount: '',
                    invoiceNo: '',
                    taxPercent: '',
                    taxValue: '',
                    remark: '',
                    pic: '',
                })
            } else {
                this.invoiceList.pop()
            }
        },

        // 发票
        inProjectChange(value, key) {
            this.formshowText.inProjectName[this.invoiceIndex] = value.projectName
            this.invoiceList[this.invoiceIndex].projectId = value.id
            this.in_projectShow = false
        },
        inDateChange(value, key) {
            this.invoiceList[this.invoiceIndex].happenDate = this.formatDate(value)
            this.in_dateShow = false
        },
        inTypeChange(value, key) {
            this.invoiceList[this.invoiceIndex].invoiceType = key
            this.in_typeShow = false
        },
        inexTypeChange(value, key) {
            this.invoiceList[this.invoiceIndex].expenseType = value.typeName
            this.in_exTypeShow = false
        },

        addInvoice() {
            this.invoiceList.push({
                projectId: '',
                happenDate: '',
                invoiceType: '',
                expenseType: '',
                amount: '',
                invoiceNo: '',
                taxPercent: '',
                taxValue: '',
                remark: '',
                pic: '',
            })
            this.uploader.push([])
            this.editForm.ticketNum = this.invoiceList.length
        },
        deleteInvoice(index) {
            this.invoiceList.splice(index, 1)
            this.uploader.splice(index, 1)
            this.editForm.ticketNum = this.invoiceList.length
        },

        // 上传报销凭证
        beforeRead(file) {
            // console.log(file, file.length)
            // console.log(Array.isArray(file))
            // if (file.type != 'image/jpeg' && file.type != 'image/png' && file.type != 'image/jpg') {
            //     this.$toast.fail('请选择jpg或png格式的图片')
            //     return false
            // }
            // return true

            let newFile = Array.isArray(file) ? file : [file]
            for(var i in newFile) {
                const fileItem = newFile[i]
                if (fileItem.type != 'image/jpeg' && fileItem.type != 'image/png' && fileItem.type != 'image/jpg') {
                    this.$toast.fail('请选择jpg或png格式的图片')
                    return false
                }
            }
            return true
        },
        afterRead(file) {
            // let formData = new FormData();
            // formData.append("multipartFile", file.file);
            // this.$axios.post("/common/uploadFile", formData)
            //     .then(res => {
            //         if (res.code == "ok") {
            //             this.invoiceList[this.invoiceIndex].pic = res.data
            //         } else {
            //             this.$toast.fail('上传失败');
            //             this.uploader[this.invoiceIndex] = []
            //         }
            //     }).catch(err => { this.$toast.clear(); console.log(err); this.uploader[this.invoiceIndex] = [] });

            let newFile = Array.isArray(file) ? file : [file]
            let formData = new FormData();
            for(var i in newFile) {
                formData.append("multipartFiles", newFile[i].file);
            }
            this.$axios.post("/common/uploadFileArray", formData)
            .then(res => {
                if (res.code == "ok") {
                    let newPic = this.invoiceList[this.invoiceIndex].pic || []
                    let newData = res.data.split(';')
                    this.invoiceList[this.invoiceIndex].pic = [...newPic, ...newData]
                    console.log(this.invoiceList[this.invoiceIndex].pic, '<=== 合计图片')
                } else {
                    this.$toast.fail('上传失败');
                    this.uploader[this.invoiceIndex] = []
                }
            }).catch(err => { this.$toast.clear(); console.log(err); this.uploader[this.invoiceIndex] = [] });
        },
        // 删除图片
        deteleImg(file, item, arrIndex) {
            let index = item.index
            this.invoiceList[arrIndex].pic = this.invoiceList[arrIndex].pic.filter((item,i) => i != index)
        },

        // 提交
        submitExpense() {
            if (!this.editForm.ownerId) {
                this.$toast.fail('请选择报销人')
                return
            }
            if (!this.editForm.createDate) {
                this.$toast.fail('请选择填报日期')
                return
            }
            if(this.auditTypeItem.auditType == 2 && this.editForm.type != 14454) {
                if(!this.firstCheckerText.id || !this.secondCheckerText.id) {
                    this.$toast.fail(`${!this.firstCheckerText.id ? '请选择第一审核人' : '请选择第二审核人'}`)
                    return
                } else {
                    this.editForm.firstCheckerId = this.firstCheckerText.id
                    this.editForm.secondCheckerId = this.secondCheckerText.id
                }
            }

            let required1 = false
            let required2 = false
            let required3 = false
            let required4 = false
            let required5 = false
            for (let i in this.invoiceList) {
                if (!this.invoiceList[i].projectId) {
                    required1 = '所属项目'
                }
                if (!this.invoiceList[i].happenDate) {
                    required2 = '费用日期'
                }
                // if (!this.invoiceList[i].invoiceType) {
                //     required3 = '发票种类'
                // }
                if (!this.invoiceList[i].expenseType) {
                    required4 = '费用类型'
                }
                if (!this.invoiceList[i].amount) {
                    if(this.user.companyId != this.mechanicalCompanyId && this.editForm.type != 14454 && this.user.id != this.editForm.ownerId) {
                        required5 = this.user.timeType.easyExpense==0?'费用金额（含税）':'费用金额'
                    }
                }
            }
            if (required1 || required2 || required3 || required4 || required5) {
                let requiredStr = (required1 ? required1 + '、' : '')
                    + (required2 ? required2 + '、' : '')
                    + (required3 ? required3 + '、' : '')
                    + (required4 ? required4 + '、' : '')
                    + (required5 ? required5 + '、' : '')
                requiredStr = requiredStr.substring(0, requiredStr.length - 1)
                this.$toast.fail('请添加发票的[' + requiredStr + ']')
                return
            }
            if (this.invoiceList.length == 0) {
                this.$toast.fail('请添加发票')
                return
            }
            for (let i in this.invoiceList) {
                this.invoiceList[i].taxValue = this.getTaxValue(this.invoiceList[i].amount, this.invoiceList[i].taxPercent)
            }
            
            let newList = JSON.parse(JSON.stringify(this.invoiceList))
            for(let i in newList) {
                if(newList[i].pic) {
                    newList[i].pic = newList[i].pic.join(',')
                }
            }
            // this.editForm.items = JSON.stringify(this.invoiceList)
            this.editForm.items = JSON.stringify(newList)
            this.editForm.totalAmount = this.totalCost
            if(this.equipmentOwner.id) {
                this.editForm.equipmentOwnerId = this.equipmentOwner.id
            }
            // 获取新的票据编号
            this.confirmLoading = true
            this.$axios.post("/expense-sheet/getNextCode", {})
                .then(res => {
                    if (res.code == "ok") {
                        this.editForm.code = res.data
                        // 提交
                        this.$axios.post("/expense-sheet/add", this.editForm)
                            .then(res => {
                                this.confirmLoading = false;
                                if (res.code == "ok") {
                                    this.$toast.success('填报成功')
                                    this.editForm = {
                                        ownerId: '',
                                        createDate: this.formatDate(new Date()),
                                        ticketNum: 0,
                                        type: 0,
                                        remark: ''
                                    }
                                    // this.formshowText = {
                                    //     name: '',
                                    //     inProjectName: []
                                    // }
                                    this.$set(this, 'firstCheckerText', {})
                                    this.$set(this, 'secondCheckerText', {})
                                    this.formshowText.name = ''
                                    this.formshowText.inProjectName = []
                                    this.expenseMainType.text=''
                                    this.invoiceList = []
                                    this.uploader = []
                                    this.formshowText.name = this.user.name;
                                    this.editForm.ownerId = this.user.id;
                                    this.userRadio = {
                                        name: this.user.name,
                                        id: this.user.id
                                    }
                                } else {
                                    this.$toast.fail(res.msg || res.code || '填报失败');
                                }
                            }).catch(err => { this.confirmLoading = false; this.$toast.clear(); console.log(err) });
                    } else {
                        this.confirmLoading = false
                        this.$toast.fail('获取失败');
                    }
                }).catch(err => {
                    this.confirmLoading = false;
                    this.$toast.clear();
                    console.log(err)
                });


        },
        // #endregion

        // 单据列表
        deleteBill(pid) {
            this.$dialog.confirm({
                message: '确认删除？',
            })
                .then(() => {
                    // on confirm
                    this.$axios.post("/expense-sheet/delete", { id: pid })
                        .then(res => {
                            if (res.code == "ok") {
                                this.$toast.success('删除成功')
                                this.getBillList()
                            } else {
                                this.$toast.fail(res.msg || res.code || '删除失败');
                            }
                        }).catch(err => { this.$toast.clear(); console.log(err) });
                })
                .catch(() => {
                    // on cancel
                });

        },
        getAuditType() {
            this.$axios.post("/expense-audit-setting/get", {})
                .then(res => {
                    if (res.code == "ok") {
                        this.auditTypeItem = res.data;
                    } else {
                        this.$message({
                            message: res.msg,
                            type: "error"
                        });
                    }
                }).catch(err => { this.$toast.clear(); console.log(err) });
        },
        

        // 单据审核
        approve(item) {
            item.approveLoading = true
            this.$axios.post("/expense-sheet/approve", { id: item.id })
                .then(res => {
                    if (res.code == "ok") {
                        this.$toast.success('已通过')
                        item.approveLoading = false
                        this.getExamineList()
                    } else {
                        this.$toast.fail('操作失败');
                        
                    }
                }).catch(err => { this.$toast.clear(); console.log(err) });
        },
        denyToReason(pid) {
            this.denyParm.id = pid
            this.denyReasonDialog = true
            this.denyParm.denyReason = ''
            this.deny()
        },
        deny() {
            this.denyLoading = true
            this.$axios.post("/expense-sheet/deny", this.denyParm)
                .then(res => {
                    if (res.code == "ok") {
                        this.$toast.success('已驳回')
                        this.denyReasonDialog = false
                        this.denyLoading = false
                        this.getExamineList()
                    } else {
                        this.$toast.fail(res.msg || res.code || '操作失败');
                    }
                }).catch(err => { this.$toast.clear(); console.log(err) });
        },

        getUserList() {
            this.$axios.post("/user/getSimpleActiveUserList", {})
                .then(res => {
                    if (res.code == "ok") {
                        this.userList = res.data
                        this.copyUserList = JSON.parse(JSON.stringify(res.data))
                    } else {
                        this.$toast.fail(res.msg || res.code || '获取失败');
                    }
                }).catch(err => { this.$toast.clear(); console.log(err) });
        },
        getProjectList() {
            this.$axios.post("/project/getProjectList", {})
                .then(res => {
                    if (res.code == "ok") {
                        this.inProjectList = res.data
                        this.inProjectListCopy = JSON.parse(JSON.stringify(res.data))
                    } else {
                        this.$toast.fail(res.msg || res.code || '获取失败');
                    }
                }).catch(err => { this.$toast.clear(); console.log(err) });
        },
        getExTypeList() {
            this.$axios.post("/expense-type/getList", {})
                .then(res => {
                    if (res.code == "ok") {
                        this.allexTypeList = res.data
                        this.inexTypeList = this.allexTypeList.filter(a => a.mainType == this.editForm.type)
                    } else {
                        this.$toast.fail(res.msg || res.code || '获取失败');
                    }
                }).catch(err => { this.$toast.clear(); console.log(err) });
        },

        getExpensMainTypes() {
            this.$axios.post("/expense-main-type/list", {})
                .then(res => {
                    if (res.code == "ok") {
                        for (var i in res.data) {
                            res.data[i].text = res.data[i].name
                        }
                        if(this.user.isMachineCost != 2) {
                            this.typeList = res.data.filter(item => item.id != 14454)
                        } else {
                            this.typeList = res.data
                        }
                        this.expenseMainType.text = res.data[0].name
                        this.editForm.type = res.data[0].id
                        this.getExTypeList
                    } else {
                        this.$toast.fail(res.msg || res.code || '获取失败');
                    }
                }).catch(err => { this.$toast.clear(); console.log(err) });
        },
        getEquipmentOwnerList() {
            this.$axios.post("/equipment-owner/getList", {})
                .then(res => {
                    if (res.code == "ok") {
                        this.equipmentOwnerList = (res.data || []).map(item => {
                            return {
                                name: item.equipmentOwner,
                                id: item.id,
                                text: item.equipmentOwner
                            };
                        });
                    } else {
                        this.$toast.fail(res.msg || res.code || '获取失败');
                    }
                }).catch(err => { this.$toast.clear(); console.log(err) });
        },
        getBillList() {
            this.$axios.post("/expense-sheet/list", {
                pageSize: 999,
                pageIndex: 1,
                startDate: '',
                endDate: '',
                ownerId: '',
                type: ''
            }).then(res => {
                if (res.code == "ok") {
                    this.billList = res.data.records
                } else {
                    this.$toast.fail(res.msg || res.code || '获取失败');
                }
            }).catch(err => { this.$toast.clear(); console.log(err) });
        },

        getExamineList() {
            this.$axios.post("/expense-sheet/list", {
                pageSize: 999,
                pageIndex: 1,
                startDate: '',
                endDate: '',
                ownerId: '',
                type: '',
                status: 1
            }).then(res => {
                if (res.code == "ok") {
                    this.examineList = res.data.records
                    for (let i in this.examineList) {
                        this.$set(this.examineList[i], 'approveLoading', false)
                    }
                } else {
                    this.$toast.fail(res.msg || res.code || '获取失败');
                }
            }).catch(err => { this.$toast.clear(); console.log(err) });
        },
        ownerIdShowCli(type) {
            if(type > 1 && this.editForm.type == '14454') return
            if(type > 1) {
                this.canExamine = true
            }
            if(this.user.isMachineCost > 1 && this.editForm.type == '14454') {
                this.canExamine = true
            }
            this.ownerIdShowType = type
            this.userRadio = ''
            this.ownerIdShow = true
        }
    },
}
</script>

<style lang="less" scoped>
.content {
    margin-top: 46px;
    overflow: auto;

    .edit {
        .userCheckbox {
            padding: 10px;
        }

        padding-bottom: 46px;

        .form_btn {
            z-index: 1000;
        }

        .edit_form {
            .invoice {
                border: 0.5px solid rgb(135, 195, 255);
                margin: 0.2rem;

                .deletebtn {
                    position: absolute;
                    z-index: 900;
                    font-size: 24px;
                    right: 0.08rem;
                    top: 0.08rem;
                    color: #c03131;
                }

                .invoice_item {
                    border-top: 0.5px solid rgb(135, 195, 255);
                }
            }

            .addinvoice {
                padding: 0 0.3rem 0.3rem;
                margin-top: 0.2rem;
                text-align: right;
            }
        }

        .imgListVan {
            display: flex;
            flex-direction: column;
        }
        .vanUploaderImg {
            // display: flex;
            margin-top: 6px;
            /deep/ .van-uploader__preview-image {
                width: 70px;
                height: 70px;
            }
        }
        /deep/ .van-uploader__upload {
            width: 70px;
            height: 70px;
        }
    }

    .list {
        .list_collapse>div {
            margin: 4px;
        }

        .list_collapse {
            .collapse_label_l {
                width: 50%;
                padding: 4px;
                display: inline-block;
            }

            .collapse_label_r {
                width: 50%;
                padding: 4px;
                display: inline-block;

                .waiting {
                    color: orange;
                }

                .rejected {
                    color: red;
                }
            }

            .wrapper {
                div {
                    margin: 8px 16px;
                }

                div span:nth-child(1) {
                    width: 30%;
                    display: inline-block;
                }

                div span:nth-child(2) {
                    width: 70%;
                    display: inline-block;
                }
            }

            .operation {
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }

    }

    .audit {
        .list_collapse>div {
            margin: 4px;
        }

        .list_collapse {

            .collapse_label_l {
                width: 60%;
                padding: 4px;
                display: inline-block;
            }

            .collapse_label_r {
                width: 40%;
                padding: 4px;
                display: inline-block;

                .waiting {
                    color: orange;
                }

                .rejected {
                    color: red;
                }
            }

            .operation {
                margin-top: 5px;
                padding-top: 5px;
                border-top: 0.5px solid #ebedf0;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                button {
                    width: 1.2rem;
                }
            }

            .wrapper {
                div {
                    margin: 8px 16px;
                }

                div span:nth-child(1) {
                    width: 30%;
                    display: inline-block;
                }

                div span:nth-child(2) {
                    width: 70%;
                    display: inline-block;
                }
            }

            .lookup {
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }

        }

    }
}
</style>
<style>
.edit_form .invoice .van-field__label {
    color: #999;
}

.edit_form .invoice .van-field__control {
    color: #999;
}
</style>